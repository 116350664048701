.fund-kpis {
	@media screen and (min-width: 992px) {
		position: relative;
		&:before {
			width: 100%;
			height: 1px;
			content: "";
			top: 50%;
			position: absolute;
			left: 0;
			display: block;
			background-color: $color-second;
		}
	}
}