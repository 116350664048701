.fund-legal-text {
	line-height: 12px;
	margin-bottom: 0;
	small {
		color: $color-gray;
	}
	&.fund-legal-text-site {
		line-height: 19px;
		small {
			font-size: 14px;
		}
	}
}