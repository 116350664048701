.hero-image {
	background: url('../../images/sample/slideshow-slide-01.jpg') no-repeat center center;
	background-size: cover;
	padding: 30px 0;
	position: relative;
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparentize($color-second, 0.5);
		display: block;
		content: '';
	}
	@media screen and(min-width: 1200px) {
		padding: 60px 0;
	}
}