table {
	width: 100%;
	border-collapse: collapse;
	border-bottom: 2px solid $color-light-gray;
	th {
		white-space: nowrap;
		font-size: 12px;
		padding: 3px;
		font-weight: bold;
		border-bottom: 2px solid $color-light-gray;
		line-height: 2;
	}
	td {
		font-size: 12px;
		padding: 3px;
		line-height: 1.5;
	}
	tbody {
		th, td {
			text-align: left;
			border-bottom: 1px solid $color-light-gray;
			vertical-align: middle;
		}
	}
}