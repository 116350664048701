.footer-logo {
	width: 170px;
	margin: 0 auto;
	.logo-alphakey {
		margin-right: 0;
	}
	@media screen and (min-width: 768px) {
		display: inline-block;
		margin-right: 30px;
	}
}