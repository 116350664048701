// Colors
$color-main: #3FC6D6;
$color-second: #5A6E88;
$color-gray: #A2AAB4;
$color-light-gray: #DDE4ED;

// Typography
$main-font-family: 'Open Sans', sans-serif;
$base-font-family: $main-font-family;
$head-font-family: 'Poppins', sans-serif;
$base-font-size: 18px;
$base-line-height: 1.8;
$base-font-color: $color-second;
$base-font-weight: 400;
$small-font-size: 11px;
$small-line-height: 1.1;

// Headings
$h1-font-size: 36px;
$h1-font-weight: 600;
$h1-line-height: $base-line-height;
$h1-font-family: $head-font-family;
$h1-font-color: $color-second;

$h2-font-size: 35px;
$h2-font-weight: 600;
$h2-line-height: $base-line-height;
$h2-font-family: $head-font-family;
$h2-font-color: $color-second;

$h3-font-size: 15px;
$h3-font-weight: 600;
$h3-line-height: $base-line-height;
$h3-font-family: $head-font-family;
$h3-font-color: $color-second;

$h4-font-size: 33px;
$h4-font-weight: normal;
$h4-line-height: $base-line-height;
$h4-font-family: $head-font-family;
$h4-font-color: $color-second;

$h5-font-size: 13px;
$h5-font-weight: normal;
$h5-line-height: $base-line-height;
$h5-font-family: $head-font-family;
$h5-font-color: $color-second;

// Layout
$base-margin-bottom: 30px; // Paragraphs, lists, blockquotes and blocks in general
$section-margin-bottom: $base-margin-bottom * 2; // Bottom margin for wide sections on the entire site
$list-item-margin-bottom: $base-margin-bottom / 2; // Margin botton inner list items
$h1-margin-bottom: $base-margin-bottom;
$h2-margin-bottom: 20px;
$h3-margin-bottom: $h2-margin-bottom;
$h4-margin-bottom: $h3-margin-bottom / 2;
$h5-margin-bottom: $h3-margin-bottom / 2;