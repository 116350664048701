.fund-promote {
	background: url('../../images/sample/divulgue-bg.jpg') no-repeat center center;
	background-size: cover;
	padding: 30px 0;
	h3 {
		font-size: 22px;
		color: white;
		margin-bottom: 0;
		line-height: 50px;
	}
}