.fund-performance-table {
	margin-bottom: 0;
	th, td {
		text-align: center;
	}
	.fund-performace-table-name {
		white-space: nowrap;
		text-align: left;
	}
	.negative {
		// color: #9A0013;
	}
	tr:nth-child(3n+4) {
		td, th {
			border-top: 2px solid $color-light-gray;
		}
	}
}