h2 {
	padding-bottom: 15px;
	position: relative;
	&:before {
		width: 40px;
		height: 3px;
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		background: $color-main;
	}
}