.sub-menu {
	margin-bottom: 0;
	margin-left: 10px;
	@media screen and (min-width: 768px) {
		display: none;
		z-index: 1;
		position: absolute;
		top: 78px;
		left: -10px;
		width: 250px;
		text-align: left;
		margin-left: 0;
		background: white;
		li {
			margin-left: 0;
			a {
				padding: 10px 20px;
				line-height: 2;
				display: block;
			}
		}
	}
}
.menu-item-has-children {
	position: relative;
	&:hover {
		.sub-menu {
			display: block;
		}
	}
}