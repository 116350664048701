input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="url"],
textarea {
	width: 100%;
	border: 0;
	border-bottom: 1px solid $color-main;
	line-height: 45px;
	outline: none;
	appearance: none;
	font-family: $main-font-family;
	resize: vertical;
}

textarea {
	height: 130px;
}