.menu-item {
	a {
		text-decoration: none;
		font-size: 15px;
		font-weight: 400;
		color: $color-second;
		line-height: 2;
		font-family: $head-font-family;
	}
	&.menu-search-button {
		a {
			font-size: 18px;
		}
	}
	@media screen and (min-width: 768px) {
		display: inline-block;
		margin: 0 10px;
		a {
			display: block;
			line-height: 78px;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	@media screen and (min-width: 1280px) {
	}
}
@media screen and (min-width: 768px) {
	.fund-header {
		.menu-item {
			a {
				line-height: 60px;
			}
		}
	}
}