.alphakey-values {
	background: #2c4654;
	padding: 60px 0;
	.row > div {
		margin-bottom: 45px;
	}
	.alphakey-value  {
		font-size: 18px;
		color: white;
		height: 55px;
		margin-bottom: 15px;
		padding-left: 65px;
		position: relative;
		display: flex;
		justify-items: center;
		align-items: center;
		&:before {
			content: attr(data-letter);
			position: absolute;
			top: 0;
			left: 0;
			width: 55px;
			height: 55px;
			line-height: 55px;
			font-size: 40px;
			text-align: center;
			color: $color-main;
			border: 1px solid $color-main;
			display: block;
		}
	}
	@media screen and (min-width: 768px) {
		background: #2c4654 url('../../images/alpha-key-bg-img.png') 100% 100%/auto no-repeat scroll;
		.row > div {
			margin-bottom: 0;
		}
	}
}