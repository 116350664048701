.fund-kpi {
	border: 1px solid $color-second;
	margin-bottom: 30px;
	position: relative;
	padding: 15px 5px 10px;
	background: white;
	&:before {
		width: 100px;
		height: 5px;
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		background: $color-second;
	}
	@media screen and (min-width: 992px) {
		margin-bottom: 0;
		height: 113px;
	}
}