$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-search: "\e908";
$icon-twitter: "\e909";
$icon-linkedin: "\e900";
$icon-facebook: "\e901";
$icon-instagram: "\e902";
$icon-fund-report: "\e903";
$icon-fund-doc: "\e904";
$icon-sheet: "\e905";
$icon-download: "\e906";
$icon-check: "\e907";

