.team-member-card {
	text-decoration: none;
	text-align: center;
	margin-bottom: 30px;
	display: block;
	img {
		max-width: 100%;
		height: auto;
	}
	h3 {
		margin-bottom: 0;
		font-weight: normal;
		font-size: 16px;
		line-height: 1.4;
	}
}