.home-feature {
	text-align: center;
	margin-bottom: 60px;
	.home-feature-icon {
		margin-bottom: 15px;
		img {
			max-width: 56px;
		}
	}
	h3 {
		font-size: 20px;
		margin-bottom: 15px;
	}
	p {
		font-size: 15px;
	}
	@media screen and (min-width: 1024px) {
		text-align: left;
		h3 {
			font-size: 22px;
		}
	}
}