.social {
	display: block;
	margin: 0 auto;
	width: 143px;
	li {
		display: inline-block;
		margin: 0 5px;
		a {
			text-decoration: none;
			color: $color-main;
			font-size: 24px;
			vertical-align: text-bottom;
		}
	}
	@media screen and (min-width: 768px) {
		display: inline-block;
	}
}