.post-card {
	a {
		text-decoration: none;
	}
	figure {
		font-size: 0;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.post-card-title {
		border-left: 1px solid $color-light-gray;
		border-right: 1px solid $color-light-gray;
		margin-bottom: 0;
		padding: 15px 15px 5px;
		font-weight: normal;
		font-size: 16px;
		height: 76px;
	}
	.post-card-footer {
		overflow: hidden;
		.post-card-meta {
			float: left;
			.post-card-category {
				font-size: 11px;
				padding: 1px 5px;
				color: $color-main;
				background: white;
				border: 1px solid $color-main;
			}
		}
		border-left: 1px solid $color-light-gray;
		border-right: 1px solid $color-light-gray;
		border-bottom: 1px solid $color-light-gray;
		padding: 10px 15px;
		.read-more-button {
			float: right;
		}
	}
}