.fund-kpi-pl {
	font-size: 12px;
	font-weight: bold;
	margin: 10px 0;
	line-height: 1;
	span {
		width: 35px;
		text-align: right;
		display: inline-block;
		font-weight: normal;
	}
	@media screen and (min-width: 1200px) {
		font-size: 14px;
		span {
			width: 70px;
		}
	}
}