.page-politicas {

	.row:before {
		content: "";
		position: absolute;
	    content: '';
	    left: 0;
	    top: 0;
	    background-color: #50C4D4;
	    width: 30%;
	    height: 27px;
	}
	a {
		color: #50C4D4;
	}
}