.button {
	background: $color-main;
	padding: 5px 15px 5px 18px;
	text-transform: uppercase;
	display: inline-block;
	outline: none;
	appearance: none;
	-webkit-appearance: none;
	font-size: 15px;
	color: white;
	text-decoration: none;
	font-family: $base-font-family;
	position: relative;
	appearance: none;
	border: none;
	cursor: pointer;
	i {
		font-size: 12px;
		margin-right: 7px;
		vertical-align: middle;
	}
	&.button-big {
		font-size: 21px;
		padding-top: 11px;
		padding-bottom: 8px;
		i {
			font-size: 30px;
		}
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto 0;
		width: 3px;
		height: 15px;
		background: white;
		display: block;
		transition: height .1s ease-in-out;
	}
	&:hover {
		&:before {
			height: 25px;
		}
	}
	&.button-alt {
		background: white;
		border: 1px solid $color-main;
		color: $color-main;
		&:before {
			background: $color-main;
		}
	}
}