.fund-invest-link {
	text-align: center;
	font-size: 0;
	height: 100%;
	margin-bottom: 60px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	img {
		max-width: 70%;
		height: auto;
		max-height: 60px;
	}
}