.midia-card {
	border: 1px solid $color-light-gray;
	width: 100%;
	.midia-card-thumbnail {
		font-size: 0;
		height: 100%;
		a {
			font-size: 0;
			height: 100%;
			display: block;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.midia-card-content {
		padding: 15px 15px 15px 0;
	}
	.midia-card-title {
		a {
			text-decoration: none;
		}
		font-size: 20px;
	}
	.midia-card-text {
		margin-bottom: 15px;
		a {
			text-decoration: none;
		}
		font-size: 80%;
	}
}