.main-header {
	background-color: white;
	transition: padding .2s ease-in-out;
	@media screen and (min-width: 1280px) {
		.container {
			position: relative;
		}
		&.fund-header {
			padding: 20px 0;
		}
		&.fixed {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 2;
			border-bottom: 1px solid $color-second;
		}
	}
}
@media screen and (min-width: 1280px) {
	.admin-bar .main-header.fixed {
		top: 32px;
	}
}