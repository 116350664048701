.team-timeline {
	border: none;
	border-collapse: collapse;
	td {
		padding: 2px 1px;
		width: 10%;
		&.active {
			.exp {
				color: white;
				background-color: $color-second;
				text-align: center;
				display: block;
				line-height: 12px;
				padding: 2px 5px;
				word-wrap: wrap;
				font-size: 11px;
			}
			&.is-alphakey .exp {
				background-color: $color-main;
			}
		}
	}
}

.timeline-image {
	max-width: 100%;
	height: auto;
}