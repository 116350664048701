.fund-chart {
	overflow-y: auto;
	margin-bottom: 30px;
	img {
		min-width: 100%;
	}
	.amcharts-Label {
		tspan {
			font-size: 13px;
			fill: #4C6478;
		}
	}
}