.address-col {
	padding-left: 30px;
}
.contact-form-col {
	padding-right: 30px;
}
address {
	h5 {
		color: $color-main;
		margin-bottom: 0;
		line-height: 2;
		font-size: 16px;
		font-weight: bold;
	}
	p {
		font-size: 16px;
	}
}