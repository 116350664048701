.fund-doc {
	margin-bottom: 15px;
	text-decoration: none;
	display: block;
	padding-left: 33px;
	background: url('../../images/fund-doc.png') no-repeat left center;
	background-size: contain;
	&.fund-report {
		background-image: url('../../images/fund-report.png');
	}
	h5 {
		font-size: 13px;
		margin-bottom: 0;
		line-height: 1.3;
	}
	span {
		font-size: 11px;
		color: $color-gray;
		display: block;
	}
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		background-image: url('../../images/fund-doc@2x.png');
		&.fund-report {
			background-image: url('../../images/fund-report@2x.png');
		}
	}
}