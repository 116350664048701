.fund-name {
	font-size: 15px;
	position: relative;
	height: 27px;
	&:before {
		width: 18px;
		height: 2px;
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		background: $color-main;
	}
	@media screen and (min-width: 768px) {
		display: inline-block;
		vertical-align: top;
	}
}