@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?knlbxx');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?knlbxx#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?knlbxx') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?knlbxx') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?knlbxx##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-fund-report {
  &:before {
    content: $icon-fund-report; 
  }
}
.icon-fund-doc {
  &:before {
    content: $icon-fund-doc; 
  }
}
.icon-sheet {
  &:before {
    content: $icon-sheet; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}

