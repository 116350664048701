html {
	font-size: 62.5%;
}
body {
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
	font-weight: $base-font-weight;
}

p, ul, ol, blockquote, table, article {
	margin-bottom: $base-margin-bottom;
	color: $base-font-color;
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

h1 {
	font-family: $h1-font-family;
	font-size: $h1-font-size;
	font-weight: $h1-font-weight;
	line-height: $h1-line-height;
	margin-bottom: $h1-margin-bottom;
	color: $h1-font-color;
}
h2 {
	font-family: $h2-font-family;
	font-size: $h2-font-size;
	font-weight: $h2-font-weight;
	line-height: $h2-line-height;
	margin-bottom: $h2-margin-bottom;
	color: $h2-font-color;
}
h3 {
	font-family: $h3-font-family;
	font-size: $h3-font-size;
	font-weight: $h3-font-weight;
	line-height: $h3-line-height;
	margin-bottom: $h3-margin-bottom;
	color: $h3-font-color;
}
h4 {
	font-family: $h4-font-family;
	font-size: $h4-font-size;
	font-weight: $h4-font-weight;
	line-height: $h4-line-height;
	margin-bottom: $h4-margin-bottom;
	color: $h4-font-color;
}
h5 {
	font-family: $h5-font-family;
	font-size: $h5-font-size;
	font-weight: $h5-font-weight;
	line-height: $h5-line-height;
	margin-bottom: $h5-margin-bottom;
	color: $h5-font-color;
}

small {
	font-size: $small-font-size;
	line-height: $small-line-height;
}