.copyright {
	font-size: $small-font-size;
	text-align: center;
	color: $color-gray;
	line-height: 60px;
	a {
		text-decoration: none;
		color: $color-gray;
	}
	@media screen and (min-width: 768px) {
		text-align: right;
	}
}