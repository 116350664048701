.team-member-profile {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,2555,255,1);
	z-index: 9;
	.team-member-profile-holder {
		.team-member-profile-close {
			position: absolute;
			top: 15px;
			right: 30px;
			background: white;
			z-index: 1;
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			color: $color-second;
		}
		padding: 15px;
		padding-top: 60px;
		height: 100vh;
		overflow: scroll;
		img {
			max-width: 100%;
			height: auto;
		}
		h3 {
			margin-bottom: 0;
			font-weight: normal;
			font-size: 26px;
			line-height: 1.4;
		}
		h5 {
			font-size: 18px;
		}
		.team-member-linkedin-link {
			font-size: 0;
			background: $color-main;
			text-align: center;
			display: block;
			width: 40px;
			height: 40px;
			line-height: 55px;
			i {
				font-size: 18px;
			}
			color: white;
			text-decoration: none;
			margin-bottom: 15px;
		}
		p {
			font-size: 15px;
		}
	}
	@media screen and (min-width: 1024px) {
		background: rgba(255,255,255,0.6);
		.team-member-profile-holder {
			box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
			background: white;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 80%;
			height: 80%;
			max-height: 480px;
		}
	}
}
.admin-bar .team-member-profile {
	top: 46px;
	@media screen and (min-width: 1024px) {
		top: 32px;
	}
}