.page-header {
	background-size: cover;
	background-position: center;
	background-image: url('../../images/header-image-default.jpg');
	padding: 80px 0 40px;
	position: relative;
	margin-bottom: 30px;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.3);
	}
	h2 {
		color: white;
		font-size: 32px;
	}
	&.page-header-compact {
		padding: 40px 0 20px;
	}
	@media screen and (min-width: 1024px) {
		margin-bottom: 60px;
		padding: 170px 0 130px;
		&.page-header-compact {
			padding: 80px 0 40px;
		}
	}
}