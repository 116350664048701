.pagination {
	text-align: center;
	.menu-paginate {
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		padding-left: none;
		margin-left: none;
		li {
			margin: 0 5px;
			a {
				display: inline-block;
				min-width: 30px;
				line-height: 30px;
				border: 1px solid $color-main;
				padding: 0 5px;
				text-decoration: none;
				color: $color-main;
			}
			&.active {
				a {
					background: $color-main;
					color: white;
				}
			}
			&.next-page,
			&.previous-page {
				a {
					border-color: white;
				}
			}
		}
	}
}