.news-categories {
	margin-bottom: 30px;
	.button-category {
		margin: 0 10px;
	}
	.current-category {
		background: $color-main;
		color: white;
		border-color: white;
		&:before {
			background: white;
		}
	}
}